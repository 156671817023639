import React, { useState } from 'react';
import '../assets/css/header.scss'
import Button from '../components/Button.js';
import config from '../config/config';
import Logo from './Logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
import { useHistory } from "react-router-dom";
import Login from '../components/modals/Login';
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-NPHSNCL'
}
TagManager.initialize(tagManagerArgs)

ReactPixel.init(197523878814889);
ReactPixel.pageView();

library.add(fas);

function Header() {
  function descriptionLogin() {
    return config.isAuthenticated() ? 'Sair' : 'Login';
  }

  // Modal Login
  const [showLogin, setShowLogin] = useState(false);
  const openModalLogin = (event, close) => {
    event.preventDefault();
    if (!config.isAuthenticated() || close) {
      setShowLogin(!showLogin);
    } else {
      config.logout();
    }
  }
  // Modal Login

  // Header Scroll
  React.useEffect(() => {
    // Toggle Main Navigation

    const toggleMenu = document.querySelector('.toggle-menu');
    const closeMenu = document.querySelector('.close-menu');
    const mainNavigation = document.querySelector('.main-navigation');
    const body = document.body;

    toggleMenu.addEventListener('click', e => {
      e.preventDefault();
      mainNavigation.classList.add('active');
      body.style.overflow = 'hidden';
    })

    closeMenu.addEventListener('click', e => {
      e.preventDefault();
      mainNavigation.classList.remove('active');
      body.style.overflow = 'auto';
    });

    document.addEventListener('click', e => {
      if (!e.target.closest('.main-navigation') && !e.target.closest('.toggle-menu')) {
        mainNavigation.classList.remove('active');
        body.style.overflow = 'auto';
      }
    });

    const header = document.querySelector('header');
    const headerHeight = header.offsetHeight;

    // Header Scroll
    window.addEventListener('scroll', () => {
      if (window.scrollY > headerHeight) {
        header.classList.add('shadow');
      } else {
        header.classList.remove('shadow');
      }
    });


  });

  const history = useHistory();
  const search = (event) => {
    event.preventDefault();
    localStorage.setItem('filterStorage', JSON.stringify({ negotiation: '1' }));
    history.push("/busca");
  }

  return (
    <header>
      <Login show={showLogin} hideModal={openModalLogin} />
      <div className="container large lt-pd flex ai-fc jc-sb">
        <Logo />
        <div className="flex ai-fc header-actions">
          <ul className="flex ai-fc mobile-none">
            <li>
              <Button url={'/anunciar'} className="btn bordered" text="Quero anunciar meu imóvel" />
            </li>
            <li>
              <a href="https://portal.rankim.com/#" onClick={openModalLogin}>{descriptionLogin()}</a>
            </li>
          </ul>
          <a href={config.url} className="toggle-menu ml-40">
          </a>
        </div>
      </div>

      <nav className="main-navigation">
        <a href="https://portal.rankim.com/#" className="close-menu">
          <FontAwesomeIcon icon={['fas', 'times']} />
        </a>

        <div className="top-menu">
          <h4 className="thin ph-40 pv-40">Navegação</h4>
          <hr className="line m-0" />
        </div>

        <div className="main-menu flex fd-fc jc-sb os h-90">
          <div>
            <ul className="mb-40 ph-20">
              <li>
                <a href="/busca" onClick={search}>Busque seu imóvel</a>
              </li>
              <li>
                <a href="/anunciar">Anuncie grátis</a>
              </li>
              <li>
                <a href="https://sejarankim.com.br" target="_blank" rel="noreferrer">Seja um corretor parceiro</a>
              </li>
              {/*<li>
                <a href="https://leads.rankim.com.br" target="_blank" rel="noreferrer">Geração de leads</a>
              </li>*/}
              <li className="li-login">
                <a href="/anunciar" onClick={openModalLogin}>{descriptionLogin()}</a>
              </li>
            </ul>
          </div>
          <div className="footer-menu h-100 pb-40">
            <ul className="pv-40 ph-20">
              <h4 className="thin ph-20 mb-20">A rankim</h4>
              <li>
                <a href="https://rankim.com.br/blog" target="_blank" rel="nofollow noopener noreferrer">
                  Conteúdo rico
                </a>
              </li>
              <li>
                <a href="https://linkedin.com/company/rankim/" target="_blank" rel="nofollow noopener noreferrer">
                  Carreiras
                </a>
              </li>
              <li>
                <a href="https://api.whatsapp.com/send?phone=+551155055808&text=Ol%C3%A1%20gostaria%20de%20receber%20atendimento." target="_blank" rel="nofollow noopener noreferrer">
                  Fale conosco
                </a>
              </li>
            </ul>
            <hr className="line" />
            <div className="pv-40 ph-20">
              <h5 className="thin mb-20">Siga a #rankim nas redes sociais</h5>
              <div className="flex ai-fc fw-w main-nav-social">
                <a href="https://www.facebook.com/voceafrente/" className="a-icon" target="_blank" rel="nofollow noopener noreferrer">
                  <FontAwesomeIcon className="icon i-24" icon={['fab', 'facebook-f']} />
                </a>
                <a href="https://www.instagram.com/sejarankim/" className="a-icon" target="_blank" rel="nofollow noopener noreferrer">
                  <FontAwesomeIcon className="icon i-24 ml-20" icon={['fab', 'instagram']} />
                </a>
                <a href="https://www.linkedin.com/company/rankim/" className="a-icon" target="_blank" rel="nofollow noopener noreferrer">
                  <FontAwesomeIcon className="icon i-24 ml-20" icon={['fab', 'linkedin-in']} />
                </a>
              </div>
            </div>
          </div>

        </div>

      </nav>
    </header>
  );
};

export default Header;