import React, {useState} from "react";
import Logo from "../../Logo";
import Stage from "./Stage";
import Button from "../../Button";
import Maps from "../../Maps";

function Acting(props) {
  
  const [form, setForm] = useState({...props.form, ...{
      cep: '',
      estado: '',
      cidade: '',
      idioma: '',
    }});
  
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setForm({...form, ...{[name]: value}});
  }
  function nextStep() {
    props.setup(props.stage + 1, form);
  }
  
  return (
    <div className="container setup">
        <div className="register-realtor-header text-center mb-80">
          <Logo className="mt-20 d-block disabled" />
          <Stage stage={props.stage} />
          <div className="w-80 m-auto">
            <h1>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed egestas id lorem ut molestie.</h1>
  
            <div className="grid col-12 id-gap mt-60">
              <div className="input-group span-6">
                <label htmlFor="cep">Cep</label>
                <input type="text" name="cep"
                       value={form.cep}
                       onChange={handleInputChange} id="cep" required="" />
              </div>
              <div className="input-group span-2">
                <label htmlFor="estado">Estado</label>
                <select name="estado" id="estado">
                </select>
              </div>
              <div className="input-group span-4">
                <label htmlFor="cidade">Cidade</label>
                <select name="cidade" id="cidade">
                </select>
              </div>
              <div className="span-12 grid col-2 id-gap">
                <p className="idioma-label">Qual o idioma da sua região?</p>
                <div className="input-group idioma-group">
                  <label htmlFor="idioma">Idioma</label>
                  <select name="idioma" id="idioma">
                  </select>
                </div>
              </div>

            </div>

            <div className="div-map">
                <Maps properties={[]}/>
              </div>
            
            <div className="footer-buttons acting grid col-3 sm-gap jc-sb ai-gc">
              <Button onClickButton={() => props.setup(props.stage - 1)} className="btn white w-100" text="Voltar" />
              <a href="/" className="help">Precisa de ajuda?</a>
              <Button onClickButton={() => nextStep()} className="btn action w-100" text="Próximo" />
            </div>
          </div>

          
        </div>
      </div>
    );
}

export default Acting;