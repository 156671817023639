import '../../assets/css/property/card.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import {library} from "@fortawesome/fontawesome-svg-core";
import React, {useEffect, useState} from 'react';
import Login from '../../components/modals/Login';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import NumberFormat from 'react-number-format';
import config from "../../config/config";
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import Tags from "../Tags";
import { LazyLoadImage } from 'react-lazy-load-image-component';
// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

library.add(far, fas)


function Card(props) {
  // Modal Login
  const [showLogin, setShowLogin] = useState(false);
  const openModalLogin = (event) => {
      event?.preventDefault();
      setShowLogin(!showLogin);
  }
  // Modal Login

  const [delayed, setDelayed] = useState(true);
  props.property.url = props.property.url.replace('https://portal.rankim.com', '');
  const property = props.property;
  const url = property.url;

  const separador = ((config.isEmptyNullOrUndefined(parseInt(property.area)) ? 0 : 1) +
                    (config.isEmptyNullOrUndefined(parseInt(property.vagas)) ? 0 : 1) +
                    (config.isEmptyNullOrUndefined(parseInt(property.quartos)) ? 0 : 1)) > 1 ? ' - ' : '';

  // Favorite
  const [favorite, setFavorite] = useState({});
  const saveFavorite = (event, id) => {
    event.preventDefault();
    if (!config.isAuthenticated()) {
        openModalLogin();
    } else {
        const isFavorite = config.addFavorite(id);
        addFavorite(isFavorite);
    }
  }

  const addFavorite = (isFavorite) => {
    setFavorite({icon: isFavorite ? ['fas','heart'] : ['far','heart'],
    text: isFavorite ? 'Favorito' : 'Favoritar',
    favoriteClass: isFavorite ? 'favorite' : ''});
  }
  // Favorite
  
  useEffect(() => {
    const timeout = setTimeout(() => setDelayed(false));

    // Set favorite
    const isFavorite = config.isFavorite(property.id);
    addFavorite(isFavorite);

    return () => clearTimeout(timeout);
  }, [property.id]);

  return delayed ? '' : (
    <div className={`swiper-slide property-item swiper-slide-active ` + (property.imagens.length === 0 ? ' none-fraction ' : '')}>
    <Swiper
      loop={true}
      navigation
      pagination={{type: 'fraction'}}
    >{property.imagens.map((images, i) => {
        return (
          <SwiperSlide key={property.id + 'img' + i} className="swiper-slide property-image" style={{ overflow: 'hidden' }}>
                   <LazyLoadImage src={images} alt={`Imagem ${i + 1} do imóvel ${property.id}`} style={{ width: '100%', height: 'auto', minHeight: '200px' }} />
          </SwiperSlide>
        )
    })
    }
    </Swiper>
    <a href={config.url} className="property-wish" onClick={(evt) => saveFavorite(evt, property.id)}>
        <FontAwesomeIcon icon={favorite.icon}/>
    </a>
    <div className="property-content">
        <div className="flex ai-fc jc-sb mb-20">
          <div>
            <Tags tags={property.tags} />
          </div>
          <a href={url}>
              <FontAwesomeIcon className="i-17" icon={['fas', 'arrow-right']}/>
          </a>
        </div>
        <a href={url} className="grid-name-price col-2 grid ai-fc jc-sb mb-30">
            <h6>{property.cidade}/{property.uf}</h6>
            <span className="price">
                <small>R$</small>
                <NumberFormat value={parseFloat(config.propertyValue(property))} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'}  decimalSeparator={','} />
            </span>
        </a>
        <a href={url} className="gray-content">
            <span className="mid-text mb-20 block">{property.bairro}</span>
            <span>
                {!config.isEmptyNullOrUndefined(parseInt(property.area)) ? `${property.area}m²${separador}` : ''}
                {!config.isEmptyNullOrUndefined(parseInt(property.quartos)) ? `${property.quartos} Dormitório${config.getPlural(property.quartos)}${separador}` : ''}
                {!config.isEmptyNullOrUndefined(parseInt(property.vagas)) ? `${property.vagas} Vaga${config.getPlural(property.vagas)}` : ''}
            </span>
        </a>
    </div>
      <Login show={showLogin} hideModal={openModalLogin}/>
</div>

  );
};

export default Card;