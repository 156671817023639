import 'react-phone-input-2/lib/bootstrap.css';
import './advertise.css';
import bgform from '../../assets/img/bg-form.png';
import novoimovel from '../../assets/img/novo-imovel.png';
import React, { useState } from "react";
import config from '../../config/config';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Phone from "../../components/Phone";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
import Login from '../../components/modals/Login';
import NumberFormat from "react-number-format";
import axios from "axios";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';

library.add(far, fab, fas)

function Advertise() {
  const EXCHANGE_URI = `${config.api}/exchange?access_token=${config.access_token}`;
  let formDefault = { name: '', cep: '', cc: '55', phone: '' }
  const [form, setForm] = useState(formDefault);
  const [limparPhone, setLimparPhone] = useState(null);

  const handleInputChange = (event) => {
    const target = event.target;
    target.classList.remove('danger-border')
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setForm({ ...form, ...{ [name]: value } });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    let isValid = true, keys = ['name', 'cep', 'cc'], input;
    keys.forEach(key => {
      if (config.isEmptyNullOrUndefined(form[key])) {
        input = document.getElementById(key);
        input.classList.add('danger-border');
        isValid = false;
      }
    })
    input = document.getElementById('phone-advertise');
    if (config.isEmptyNullOrUndefined(form['phone'])) {
      input.classList.add('danger-border');
      isValid = false;
    }
    if (isValid) {
      let body = new FormData();
      body.append('name', form.name);
      body.append('phone', (form.cc + form.phone.replace(/\D/g, "")));
      body.append('cep', form.cep.replace(/\D/g, ""));

      let post = { method: 'post', url: `${EXCHANGE_URI}`, headers: { "content-type": "application/x-www-form-urlencoded" }, data: body };

      const res = await axios(post)
      if (res.data?.response?.status) {
        setForm(formDefault);
        setLimparPhone(new Date());
      }
    }
  }

  // Modal Login
  const [showLogin, setShowLogin] = useState(false);
  const openModalLogin = (event) => {
    event.preventDefault();
    setShowLogin(!showLogin);
  }
  // Modal Login

  return (
    <div id="main">

      <Helmet>
        <meta charSet="utf-8" />
        <meta name="keywords" content="plataforma,automação,leads,corretor,whatsapp,landing page,captação de leads,crm corretor,crm imobiliária,marketing,rankim,geração de leads,corretor de imóveis" />
        <meta name="description" content="Plataforma de automação de marketing, captação de leads e CRM para corretores de imóveis e imobiliárias, conteúdo exclusivo" />
        <meta name="author" content="rankim" />
        <title>rankim: Plataforma de compra e venda de imóveis</title>
        <meta property="fb:app_id" content="" />
        <meta property="og:type" content="article" />
        <meta property="profile:username" content="voceafrente" />
        <meta property="og:title" content="Plataforma imobiliária de marketing e CRM para.." />
        <meta property="og:description" content="Plataforma de automação de marketing, captação.." />
        <meta property="og:url" content="https://rankim.com.br/" />
        <meta property="og:site_name" content="rankim" />
        <meta property="og:see_also" content="https://rankim.com.br/" />
        <meta property="og:image" content="https://rankim.com.br/assets/website/images/video/02.jpg" />
        <meta property="og:image:url" itemprop="image" content="https://rankim.com.br/assets/website/images/video/02.jpg" />
        <meta property="og:image:type" content="image/jpg" />
      </Helmet>

      <Login show={showLogin} hideModal={openModalLogin} />

      <Header />
      <main>

        <section id="banner">
          <div className="container large md-pd grid col-12 md-gap ai-gc">
            <div className="span-5 text-center-mobile">
              <h1 className="big bolder mb-30">Anunciar seu imóvel é muito mais rápido pela rankim.</h1>
              <p className="mb-40">Estratégias são indispensáveis para multiplicar resultados e tornar-se visível. Sendo assim, a rankim aplica técnicas condutoras de marketing para atingir o seu público alvo e vender mais rápido o seu imóvel. </p>
              {/* <p className="mid-text bold">Tem alguma dúvida? Ligue <a href="tel:+5540072972">4007 2972</a></p> */}
            </div>
            <div className="span-7 get-contact flex ai-fc jc-fc">
              <form className="content-item gray grid md-gap relative" onSubmit={handleSubmit}>
                <LazyLoadImage alt="Anunciar seu imóvel é muito mais rápido pela rankim." src={bgform} className="bg-form" />
                <div className="input-group">
                  <label htmlFor="name">Nome completo</label>
                  <input type="text" name="name"
                    value={form.name}
                    onChange={handleInputChange} id="name" required="" />
                </div>
                <div className="input-group">
                  <label htmlFor="cep">CEP do endereço</label>
                  <NumberFormat name="cep" id="cep" value={form.cep} onChange={handleInputChange} format="##.###-###" />
                </div>
                <Phone emit={(data) => setForm({ ...form, ...data })} limpar={limparPhone} phoneId="advertise" />
                <button type="submit" className="btn action big full text-black">Quero anunciar meu imóvel</button>
              </form>
            </div>
          </div>
        </section>

        <section id="temos">

          <div className="container large md-pd grid col-4 md-gap">
            <h2 className="big bolder text-center w800 span-4">Veja nossos diferencias</h2>
            <div className="content-item flex fd-fc">
              <FontAwesomeIcon className="i-48 mb-30 color-primary" icon={['far', 'eye']} />
              <h4 className="bolder mb-30">Acompanhe todas as visitas e propostas</h4>
              <p>
                Você anuncia seu imóvel e fica sabendo de tudo o que acontece com ele, direto pelo aplicativo. Confira em
                alguns cliques a agenda de visitas, avaliações dos compradores e dicas para aumentar o interesse no seu
                imóvel.
              </p>
            </div>
            <div className="content-item flex fd-fc">
              <FontAwesomeIcon className="i-48 mb-30 color-primary" icon={['far', 'handshake']} />
              <h4 className="bolder mb-30">Propostas de compradores realmente interessados</h4>
              <p>
                Acompanhamos de perto a negociação. Nossos consultores indicam as melhores taxas de financiamento para
                quem quer comprar seu imóvel. Agilizamos o processo de aprovação de crédito para que fechem negócio com
                segurança e em menos tempo.
              </p>
            </div>
            <div className="content-item flex fd-fc">
              <FontAwesomeIcon className="i-48 mb-30 color-primary" icon={['far', 'file-alt']} />
              <h4 className="bolder mb-30">Ajudamos você com toda a documentação</h4>
              <p>
                Ajudamos você com cada detalhe da transferência da escritura do imóvel. Cuidamos de todas
                as partes burocráticas que causam dor de cabeça e você nem precisará sair de casa.
              </p>
            </div>
            <div className="content-item flex fd-fc">
              <FontAwesomeIcon className="i-48 mb-30 color-primary" icon={['fas', 'key']} />
              <h4 className="bolder mb-30">Transferência de escritura facilitada</h4>
              <p>
                Nós te ajudamos com cada detalhe da transferência da escritura do imóvel. Cuidamos de todas as partes
                burocráticas que causam dor de cabeça pra você não precisar sair de casa.
              </p>
            </div>
          </div>

        </section>

        <section id="como-funciona">
          <div className="container w1100 bg-pd new-property">
            <div className="content-item grid ai-gc col-12">
              <div className="span-5 img-cont">
                <LazyLoadImage src={novoimovel} alt="Controle" />
              </div>
              <div className="span-7 sm-pd">
                <div className="span-7 sm-pd text-center-mobile">
                  <h4 className="bolder mb-30">Venda muito mais rápido</h4>
                  <p>Através de toda tecnologia e experiência da rankim, os imóveis são alugados e vendidos até 10 vezes mais rápido.
                     E agora, toda esta inteligência está disponível para você acelerar suas vendas.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="how-work-property">
          <div className="container md-pd">
            <h2 className="big bolder text-center">Como funciona?</h2>
          </div>

          <div className="light-bg">
            <div className="container md-pd light-bg">
              <p className="mid-text mb-40 bolder">Entenda cada passo da sua jornada</p>

              <div className="grid bg-gap col-2 mb-40">
                <div className="bg-step" />
                <div>
                  <ul className="step-list">
                    <li className="flex pv-40">
                      <div className="circle-numbered small white mr-20">
                        1
                      </div>
                      <div className="pt-30">
                        <h5 className="bolder mb-20">Anúncio e fotos</h5>
                        <p>Anuncie seu imóvel de graça e com fotos profissionais.</p>
                      </div>
                    </li>
                    <li className="flex pv-40">
                      <div className="circle-numbered small white mr-20">
                        2
                      </div>
                      <div className="pt-30">
                        <h5 className="bolder mb-20">Receba visitas e propostas</h5>
                        <p>Acompanhe o agendamento de visitas pelo aplicativo e receba propostas através dos nossos
                          consultores.</p>
                      </div>
                    </li>
                    <li className="flex pv-40">
                      <div className="circle-numbered small white mr-20">
                        3
                      </div>
                      <div className="pt-30">
                        <h5 className="bolder mb-20">Negociação intermediada</h5>
                        <p>Nós intermediamos a negociação com o comprador para você seguir com mais confiança e
                          segurança.</p>
                      </div>
                    </li>
                    <li className="flex pv-40">
                      <div className="circle-numbered small white mr-20">
                        4
                      </div>
                      <div className="pt-30">
                        <h5 className="bolder mb-20">Compromisso de compra e venda</h5>
                        <p>Após a aprovação da proposta, você e o interessado assinam de forma online e segura o
                          compromisso de compra e venda.</p>
                      </div>
                    </li>
                    <li className="flex pv-40">
                      <div className="circle-numbered small white mr-20">
                        5
                      </div>
                      <div className="pt-30">
                        <h5 className="bolder mb-20">Documentos verificados</h5>
                        <p>Verificamos os documentos do imóvel e do comprador sem custos adicionais, para garantir
                          que vai dar tudo certo com a venda.</p>
                      </div>
                    </li>
                    <li className="flex pv-40">
                      <div className="circle-numbered small white mr-20">
                        6
                      </div>
                      <div className="pt-30">
                        <h5 className="bolder mb-20">Assinatura do Contrato</h5>
                        <p>Nós cuidamos de todo o processo do cartório. Levaremos o contrato até você e ao comprador para
                          que seja assinado. Em seguida registramos no cartório.</p>
                      </div>
                    </li>
                    <li className="flex pv-40">
                      <div className="circle-numbered small white mr-20">
                        7
                      </div>
                      <div className="pt-30">
                        <h5 className="bolder mb-20">Dinheiro na sua mão</h5>
                        <p>Depois que a transação estiver completa, você já recebe o valor da venda. Fácil assim!</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="faq">
          <div className="container bg-pd grid md-gap">
            <h2 className="big bolder mb-20 text-center">Perguntas frequentes</h2>
            <div className="content-item gray">
              <div className="w1100">
                <h5 className="bolder mb-20">Ainda não terminei de pagar meu imóvel. Posso vender?</h5>
                <p>
                  Sim. Se a pessoa que for comprar o imóvel resolver financiar, o valor que você estiver devendo vai ser
                  quitado e você receberá somente o restante. Mas se a pessoa interessada resolver comprar à vista, você
                  vai precisar quitar o seu financiamento e pedir o Termo de Quitação do contrato para o seu banco.
                  Depois disso, o imóvel já pode passar para o nome de quem realizou a compra.
                </p>
              </div>
            </div>
            <div className="content-item gray">
              <div className="w1100">
                <h5 className="bolder mb-20">Em quais cidades o serviço já está disponível?</h5>
                <p>
                  Hoje vendemos imóveis nas seguintes regiões de Santa Catarina: Balneário Camboriú, Itajaí,
                  Itapema, Camboriú. Em breve expandiremos para outras cidades do país.
                </p>
              </div>
            </div>
            <div className="content-item gray">
              <div className="w1100">
                <h5 className="bolder mb-20">Posso colocar meu imóvel para venda e para aluguel?</h5>
                <p>
                  Pode. Nesse caso, o imóvel receberá visitas e propostas para aluguel e venda. Caso você alugue o seu
                  imóvel, o seu anúncio de venda continuará ativo, mas será importante comunicar o seu inquilino sobre o
                  direito de preferência sobre a compra da Lei do Inquilinato.
                </p>
              </div>
            </div>
          </div>
        </section>

      </main>

      <Footer />

    </div>
  );
}

export default Advertise;
