import React, {useEffect, useState} from "react";
import Button from "../../../components/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../../assets/css/modals/components/loginRealtorCode.scss';
import Logo from "../../Logo";
import config from "../../../config/config";

function LoginRealtorCode(props) {
  const [code, setCode] = useState({ code1: '', code2: '', code3: '', code4: '', code5: '', code6: '' });
  const [initValidate, setInitValidate] = useState(false);
  const [codeValid, setCodeValid] = useState(null);
  
  const defaultTime = 60;
  const [time, setTime] = useState(defaultTime);
  const [timeShow, setTimeShow] = useState('01:00');
  
  function resend() {
    props.send(props.data);
    setTime(defaultTime);
  }
  
  useEffect(() => {
    let code1 = document.getElementById('code1');
    if (code1) code1.focus();
  }, [])
  
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(time - 1);
      if((time - 1) >= -1) {
        let min = parseInt(time / 60);
        let seg = time % 60;
        if (min < 10) {
          min = "0" + min;
          min = min.substr(0, 2);
        }
        if (seg <= 9) {
          seg = "0" + seg;
        }
        setTimeShow(`${min}:${seg}`)
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [time])
  
  
  
  const handleInputChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setCode({...code, ...{[name]: value}});
    if (!config.isEmptyNullOrUndefined(value)) {
      let nextName = 'code' + (parseInt(name.replace('code', '')) + 1);
      let next = document.getElementById(nextName);
      if (next) {
        setCode({...code, ...{[name]: value}, ...{[nextName]: ''}});
        next.focus();
      } else {
        setInitValidate(true);
      }
    } else {
      let backName = 'code' + (parseInt(name.replace('code', '')) - 1);
      let back = document.getElementById(backName);
      if (back) {
        back.focus();
      }
    }
  }
  
  useEffect(() => {
    function validateCode() {
      let retorno = true;
      setCodeValid(retorno);
      if (retorno) {
        props.setup(1);
      }
    }

    if (initValidate) validateCode();
  }, [initValidate, props])
    
    
    return (
    <div className="container small">
        <div className="register-realtor-code-header text-center mb-80">
          <a className="back" href="https://portal.rankim.com/#" onClick={props.back}>
            <FontAwesomeIcon className="icon mr-10" icon={['fas', 'arrow-left']} /> Voltar
          </a>
          <Logo className="mt-100 d-block disabled" />
          <h2 className="mb-30">Qual o código que você recebeu?</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed egestas id lorem ut molestie.</p>
        </div>

        <div className="grid ai-gc">
          <div className="form-code flex fw-w jc-fc mb-50">
            <input type="text" maxLength="1" tabIndex={1} id="code1"
                   value={code.code1} name="code1" onChange={handleInputChange}/>
            <input type="text" maxLength="1" tabIndex={2} id="code2"
                   value={code.code2} name="code2" onChange={handleInputChange}/>
            <input type="text" maxLength="1" tabIndex={2} id="code3"
                   value={code.code3} name="code3" onChange={handleInputChange}/>
            <input type="text" maxLength="1" tabIndex={4} id="code4"
                   value={code.code4} name="code4" onChange={handleInputChange}/>
            <input type="text" maxLength="1" tabIndex={5} id="code5"
                   value={code.code5} name="code5" onChange={handleInputChange}/>
            <input type="text" maxLength="1" tabIndex={6} id="code6"
                   value={code.code6} name="code6" onChange={handleInputChange}/>
          </div>
          {codeValid === false ? <span className="info-code">Código inválido</span> : null}
          <span className="info-phone">
            <FontAwesomeIcon className="icon mr-10" icon={['far', 'clock']} />
            {timeShow} para poder reenviar
          </span>

          <Button onClickButton={resend} disabled={time > 0} className="btn white w-70" text="Reenviar Código" />
        </div>
      </div>
    );
}

export default LoginRealtorCode;