
import './realtor.css';
import publicocerto from '../../assets/img/tenha-o-publico-certo-na-sua-mao.png';
import melhorestecnologias from '../../assets/img/as-melhores-tecnologias.png';
import controle from '../../assets/img/controle.png';
import React, { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
import Button from "../../components/Button";
import RegisterRealtor from "../../components/modals/RegisterRealtor";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';

library.add(fas);

function Realtor() {

  // Modal RegisterRealtor
  const [showRegisterRealtor, setShowRegisterRealtor] = useState(false);

  const openModalRegisterRealtor = (event, show = true) => {
    event.preventDefault();
    setShowRegisterRealtor(show);
  }
  // Modal RegisterRealtor

  return (
    <div id="main">

      <Helmet>
        <meta charSet="utf-8" />
        <meta name="keywords" content="plataforma,automação,leads,corretor,whatsapp,landing page,captação de leads,crm corretor,crm imobiliária,marketing,rankim,geração de leads,corretor de imóveis" />
        <meta name="description" content="Plataforma de automação de marketing, captação de leads e CRM para corretores de imóveis e imobiliárias, conteúdo exclusivo" />
        <meta name="author" content="rankim" />
        <title>rankim: Plataforma de compra e venda de imóveis</title>
        <meta property="fb:app_id" content="" />
        <meta property="og:type" content="article" />
        <meta property="profile:username" content="voceafrente" />
        <meta property="og:title" content="Plataforma imobiliária de marketing e CRM para.." />
        <meta property="og:description" content="Plataforma de automação de marketing, captação.." />
        <meta property="og:url" content="https://rankim.com.br/" />
        <meta property="og:site_name" content="rankim" />
        <meta property="og:see_also" content="https://rankim.com.br/" />
        <meta property="og:image" content="https://rankim.com.br/assets/website/images/video/02.jpg" />
        <meta property="og:image:url" itemprop="image" content="https://rankim.com.br/assets/website/images/video/02.jpg" />
        <meta property="og:image:type" content="image/jpg" />
      </Helmet>

      <RegisterRealtor show={showRegisterRealtor} hideModal={(evt) => openModalRegisterRealtor(evt, false)} />
      <Header />

      <main>

        <section id="banner">
          <div className="container large md-pd grid col-12 md-gap ai-gc">
            <div className="span-5 text-center-mobile">
              <h1 className="big bolder mb-30">Tenha o público certo na sua mão!</h1>
              <p className="mb-40">Com a tecnologia certa, público-alvo segmentado, marketing digital aplicado para encontrar
              bons clientes, facilidade e agilidade, sem dor de cabeça, você sai na frente sendo um corretor
              parceiro rankim.</p>
              {/* <Button onClickButton={(evt) => openModalRegisterRealtor(evt)} className="btn action big bolder" text="Seja um corretor parceiro" /> */}
              <Button url="https://sistema.rankim.com.br" className="btn action big bolder mr-10 text-black" text="Login na plataforma" />
              <Button url="https://rankim.com.br/registro/corretor-parceiro.html" className="btn info big bolder" text="Criar conta" />
            </div>
            <div className="span-7">
              <LazyLoadImage src={publicocerto} alt="Tenha o público certo na sua mão" />
            </div>
          </div>
        </section>

        <section id="parceria">
          <div className="container md-pd">
            <h5 className="mb-40 text-center">Veja como funciona nossa parceria</h5>
            <div className="grid col-3 md-gap how-work-cont mb-40">
              <div className="how-work-item flex ai-fc">
                <div className="circle-numbered bolder mr-20">
                  1
               </div>
                <div>
                  <h6 className="bolder mb-20">Indicação</h6>
                  <p>Você indica e cadastra o imóvel, nós encontramos o cliente ideal. </p>
                </div>
              </div>
              <div className="how-work-item flex ai-fc">
                <div className="circle-numbered bolder mr-20">
                  2
               </div>
                <div>
                  <h6 className="bolder mb-20">Negociação</h6>
                  <p>Nós agendamos a visita e juntos trabalhamos a venda.</p>
                </div>
              </div>
              <div className="how-work-item flex ai-fc">
                <div className="circle-numbered bolder mr-20">
                  3
               </div>
                <div>
                  <h6 className="bolder mb-20">Bonificação</h6>
                  <p>Com o imóvel vendido você recebe uma comissão exclusiva.</p>
                </div>
              </div>
            </div>
            <div className="flex ai-fc jc-fc">

            </div>
          </div>
        </section>

        <section id="tecnologias">
          <div className="container md-pd">
            <h2 className="big text-center mb-20 w800 bolder">As melhores tecnologias</h2>
            <p className="text-center w800 mb-40">Todo mundo quer se destacar, mas a verdade é que nem todos sabem por onde começar. A
            rankim utiliza tecnologia de ponta, anos de experiência e inteligência artificial para promover
            suas indicações e agilizar processos.</p>
          </div>
          <div className="relative">
            <div className="container grid col-12 md-gap">
              <div className="span-4 grid sm-gap ai-gc">
                <div className="content-item flex ai-fs">
                  <FontAwesomeIcon className="icon-map i-48 mr-30" icon={['fas', 'map-marked-alt']} />
                  <div>
                    <h3 className="bolder mb-20">Mapeamento</h3>
                    <p>Enquanto você  mapeia a região em busca de imóveis para aumentar a sua carteira e vender. A rankim encontra os melhores interessados em comprar!</p>
                    <p>Com apenas um clique é possível acompanhar, na palma da sua mão, todo o processo no painel de gestão.</p>
                  </div>
                </div>
                <div className="content-item bg-white flex ai-fs">
                  <FontAwesomeIcon className="icon-map i-48 mr-30" icon={['fas', 'filter']} />
                  <div>
                    <h3 className="bolder mb-20">Funil de Vendas</h3>
                    <p>Nosso funil de conversão de vendas com as principais fases de negócio, é atualizado em tempo
                    real. Com ele você controla a quantidade de leads que já passaram por cada fase do
                    atendimento e, com apenas um clique, pode visualizar todos os leads dentro de cada uma
                    delas. </p>
                  </div>
                </div>
                <div className="content-item flex ai-fs">
                  <FontAwesomeIcon className="icon-map i-48 mr-30" icon={['fas', 'stream']} />
                  <div>
                    <h3 className="bolder mb-20">Pipeline</h3>
                    <p>Ao acessar o painel você tem uma visão geral das principais informações de atendimento
                    como imóveis anunciados, publicados e o total de leads.</p>
                  </div>
                </div>
              </div>
              <div className="span-8 tablet-none">
                <LazyLoadImage src={melhorestecnologias} alt="As melhores tecnologias" />
              </div>
            </div>
          </div>
          <div className="container w1100 bg-pd control">
            <div className="content-item grid ai-gc col-12">
              <div className="span-5 img-cont">
                <LazyLoadImage src={controle} alt="Controle" />
              </div>
              <div className="span-7 sm-pd">
                <ul className="mid-text grid lt-gap">
                  <li className="flex bolder ai-fc jc-fc-mobile">
                    <FontAwesomeIcon className="icon-map mr-10" icon={['fas', 'check']} />
                   Gerencie sua carteira de imóveis
                 </li>
                  <li className="flex bolder ai-fc jc-fc-mobile">
                    <FontAwesomeIcon className="icon-map mr-10" icon={['fas', 'check']} />
                   Controle seu time de vendas
                 </li>
                  <li className="flex bolder ai-fc jc-fc-mobile">
                    <FontAwesomeIcon className="icon-map mr-10" icon={['fas', 'check']} />
                   Integre com seu site e Facebook
                 </li>
                  <li className="flex bolder ai-fc jc-fc-mobile">
                    <FontAwesomeIcon className="icon-map mr-10" icon={['fas', 'check']} />
                   Receba suas Leads direto no Whatsapp
                 </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section id="temos" className="bg-city">

          <div className="container md-pd">
            <h2 className="big text-center w800 mb-20 bolder">Sabe o que oferecemos?</h2>
            <p className="w800 text-center">Além de um portal e uma plataforma completa com ferramentas de captação e gerenciamento
            de clientes, a rankim possui anos de experiência no mercado imobiliário unindo quem quer
            comprar com quem quer vender, com facilidade, segurança e rapidez.</p>
          </div>

          <div className="container md-pd grid col-4 md-gap mb-40">
            <div className="content-item content-item-gtr grid mh-20">
              <FontAwesomeIcon className="icon-map i-48" icon={['fas', 'briefcase']} />
              <h4 className="bolder">Simplifique seu trabalho</h4>
              <p>Foque na sua captação, aumente sua carteira de imóveis e deixe a rankim trabalhar por (ou
              para) você.</p>
            </div>
            <div className="content-item content-item-gtr grid mh-20">
              <FontAwesomeIcon className="icon-map i-48" icon={['fas', 'hand-holding-usd']} />
              <h4 className="bolder">Receba quando vender</h4>
              <p>Com o imóvel indicado e vendido você recebe uma comissão exclusiva!</p>
            </div>
            <div className="content-item content-item-gtr grid mh-20">
              <FontAwesomeIcon className="icon-map i-48" icon={['fas', 'chart-line']} />
              <h4 className="bolder">Aumente suas vendas</h4>
              <p>Oferecemos as ferramentas e a tecnologia que um corretor precisa para obter muito sucesso.
              Venda mais imóveis com a rankim trabalhando por você!</p>
            </div>
            <div className="content-item content-item-gtr grid mh-20">
              <FontAwesomeIcon className="icon-map i-48" icon={['fas', 'mobile']} />
              <h4 className="bolder">Facilidade</h4>
              <p>Site e aplicativo modernos com filtros inteligentes, que ajudam a encontrar os imóveis de um
              jeito rápido e fácil.</p>
            </div>

            <div className="content-item content-item-gtr grid mh-20">
              <FontAwesomeIcon className="icon-map i-48" icon={['fas', 'users']} />
              <h4 className="bolder">Qualificação de Leads</h4>
              <p>Usamos o poder do marketing e nossa larga experiência para trabalhar a sua carteira de
              imóveis da melhor forma.</p>
            </div>
            <div className="content-item content-item-gtr grid mh-20">
              <FontAwesomeIcon className="icon-map i-48" icon={['fas', 'headphones']} />
              <h4 className="bolder">Plantão virtual</h4>
              <p>Dúvidas também podem ser tiradas a qualquer momento com nossos atendentes virtuais.</p>
            </div>
            <div className="content-item grid content-item-gtr ai-gc mh-20">
              <FontAwesomeIcon className="icon-map i-48" icon={['fas', 'mouse']} />
              <h4 className="bolder">Gestão de carteira</h4>
              <p>Com poucos cliques você consegue gerir informações e publicar os imóveis em várias
              plataformas.</p>
            </div>
            <div className="content-item grid content-item-gtr ai-gc mh-20">
              <FontAwesomeIcon className="icon-map i-48" icon={['fas', 'gamepad']} />
              <h4 className="bolder">Gameficação</h4>
              <p>Participe de um ranking com os melhores corretores do Brasil.</p>
            </div>
          </div>

        </section>
        {/*<section id="table-price">
        <ListPlans className="bg-pd" />
              </section>*/}
      </main>

      <Footer />

    </div>
  );
}

export default Realtor;
