import React, {useState} from "react";
import Logo from "../../Logo";
import Stage from "./Stage";
import Button from "../../Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Integrations(props) {
  
  const [form, setForm] = useState({...props.form, ...{
    facebook: false,
    youtube: false,
    google_ads: false,
    dominio: false,
    instagram: false
  }});
  
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setForm({...form, ...{[name]: value}});
  }
  function nextStep() {
    props.setup(props.stage + 1, form);
  }
  
  return (
    <div className="container setup integrations-setup">
        <div className="register-realtor-header text-center mb-80">
          <Logo className="mt-20 d-block disabled" />
          <Stage stage={props.stage} />
          <div>
            <h1 className="integrations-48 m-auto">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed egestas id lorem ut molestie.</h1>
            <div className="img-back" />
            <div className="integrations-items grid col-3 sm-gap mb-50 mt-30">

              <div className="integrations-item grid col-10 sm-gap">
                <FontAwesomeIcon className="icon i-37" icon={['fab', 'facebook-f']} />
                <div className="content span-7 text-left">
                  <div className="title">Facebook</div>
                  <div className="description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </div>
                </div>
                <div className="span-2 js-ge">
                  <label className="switch">
                    <input type="checkbox" name="facebook"
                    checked={form.facebook} onChange={handleInputChange} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>

              <div className="integrations-item grid col-10 sm-gap">
                <FontAwesomeIcon className="icon i-37" icon={['fab', 'youtube']} />
                <div className="content span-7 text-left">
                  <div className="title">You Tube</div>
                  <div className="description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </div>
                </div>
                <div className="span-2 js-ge">
                  <label className="switch">
                    <input type="checkbox" name="youtube"
                    checked={form.youtube} onChange={handleInputChange} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>

              <div className="integrations-item grid col-10 sm-gap">
                <FontAwesomeIcon className="icon i-37" icon={['fas', 'bullhorn']} />
                <div className="content span-7 text-left">
                  <div className="title">Google ADS</div>
                  <div className="description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </div>
                </div>
                <div className="span-2 js-ge">
                  <label className="switch">
                    <input type="checkbox" name="google_adds"
                    checked={form.google_adds} onChange={handleInputChange} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>

              <div className="integrations-item grid col-10 sm-gap">
                <FontAwesomeIcon className="icon i-37" icon={['fas', 'globe']} />
                <div className="content span-7 text-left">
                  <div className="title">Domínio Personalizado</div>
                  <div className="description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </div>
                </div>
                <div className="span-2 js-ge">
                  <label className="switch">
                    <input type="checkbox" name="dominio"
                    checked={form.dominio} onChange={handleInputChange} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>

              <div className="integrations-item grid col-10 sm-gap">
                <FontAwesomeIcon className="icon i-37" icon={['fab', 'instagram']} />
                <div className="content span-7 text-left">
                  <div className="title">Instagram</div>
                  <div className="description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </div>
                </div>
                <div className="span-2 js-ge">
                  <label className="switch">
                    <input type="checkbox" name="instagram"
                    checked={form.instagram} onChange={handleInputChange} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              
            </div>
            <p className="integrations-48 m-auto">Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br />Sed egestas id lorem ut molestie.</p>
  
            <div className="footer-buttons integrations w-80 grid col-3 sm-gap jc-sb ai-gc">
              <Button onClickButton={() => props.setup(props.stage - 1)} className="btn white w-100" text="Voltar" />
              <a href="/" className="help">Precisa de ajuda?</a>
              <Button onClickButton={() => nextStep()} className="btn action w-100" text="Próximo" />
            </div>
          </div>

          
        </div>
      </div>
    );
}

export default Integrations;