import React, { useEffect, useState } from 'react';
import Routes from '../config/routes';
import '../assets/css/app.scss';
import axios from 'axios';
import Cookies from "./Cookies";
import config from "../config/config";

function App() {
  const TAGS_URI = `${config.api}/tags?access_token=${config.access_token}`;
  const CHANNELS_URI = `${config.api}/channels?access_token=${config.access_token}`;
  const TOTALS_URI = `${config.api}/totals?access_token=${config.access_token}`;
  const NEGOTIATIONS_URI = `${config.api}/negotiations?access_token=${config.access_token}`;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const resT = await axios.get(`${TAGS_URI}`);
      config.tags = resT?.data?.response;
      const resC = await axios.get(`${CHANNELS_URI}`);
      config.channels = resC?.data?.response;
      const resTotals = await axios.get(`${TOTALS_URI}`);
      config.totals = resTotals?.data?.response;
      const resN = await axios.get(`${NEGOTIATIONS_URI}`);
      config.negotiations = resN?.data?.response;
      setLoading(false);
    }
    (async function getDataAsync() {
      await getData();
    })();
  }, [TAGS_URI, CHANNELS_URI, TOTALS_URI, NEGOTIATIONS_URI])

  return (
    <div className="app">
      {loading ? <div className="loader loader-default is-active" /> : <Routes />}
      <Cookies />
    </div>
  );
}

export default App;