import React, {useState} from "react";
import Logo from "../../Logo";
import Stage from "./Stage";
import Button from "../../Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function Notifications(props) {
  
  const [form, setForm] = useState({...props.form, ...{}});
  
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setForm({...form, ...{[name]: value}});
  }
  function nextStep() {
    props.setup(props.stage + 1, form);
  }
  
  return (
    <div className="container setup">
        <div className="register-realtor-header text-center mb-80">
          <Logo className="mt-20 d-block disabled" />
          <Stage stage={props.stage} />
          <div className="w-80 m-auto">
            <h1>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed egestas id lorem ut molestie.</h1>
            <div className="img-back" />
  
            <div className="grid col-1 type-contact mb-50 mt-30">
              <label className="flex ai-fc normal jc-fc">
      
                <input type="checkbox" name="via_email"
                       checked={form.via_email} onChange={handleInputChange} />
                <span>
                <FontAwesomeIcon className="icon icon-contact float-left" icon={['far', 'envelope']} />
                <div className="float-left">Via E-mail</div>
                <FontAwesomeIcon className={`icon ml-10 float-right ` + (!form.via_email ? 'check-disabled' : '')}
                                 icon={['fas', (form.via_email ? 'check-circle' : 'circle')]} />
              </span>
              </label>
              <label className="flex ai-fc normal jc-fc">
                <input type="checkbox" name="via_whatsapp"
                       checked={form.via_whatsapp} onChange={handleInputChange} />
                <span>
                <FontAwesomeIcon className="icon icon-contact float-left" icon={['fab', 'whatsapp']} />
                <div className="float-left">Via Whatsapp</div>
                <FontAwesomeIcon className={`icon ml-10 float-right ` + (!form.via_whatsapp ? 'check-disabled' : '')}
                                 icon={['fas', (form.via_whatsapp ? 'check-circle' : 'circle')]} />
              </span>
              </label>
              <label className="flex ai-fc normal jc-fc">
                <input type="checkbox" name="via_sms"
                       checked={form.via_sms} onChange={handleInputChange} />
                <span>
                <FontAwesomeIcon className="icon icon-contact float-left" icon={['fas', 'sms']} />
                <div className="float-left">Via SMS</div>
                <FontAwesomeIcon className={`icon ml-10 float-right ` + (!form.via_sms ? 'check-disabled' : '')}
                                 icon={['fas', (form.via_sms ? 'check-circle' : 'circle')]} />
              </span>
              </label>
            </div>
  
            <div className="flex jc-sb ai-gc mt-100">
              <Button onClickButton={() => props.setup(props.stage - 1)} className="btn white w-100" text="Voltar" />
              <a href="/" className="help">Precisa de ajuda?</a>
              <Button onClickButton={() => nextStep()} className="btn action w-100" text="Próximo" />
            </div>
          </div>

          
        </div>
      </div>
    );
}

export default Notifications;