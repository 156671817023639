import React from "react";
import Button from "../components/Button";

import SwiperCore, {Navigation, Pagination} from "swiper";

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
// install Swiper components
SwiperCore.use([Navigation, Pagination]);

function ListPlans(props) {
  
  let breakpoints = {
    300: {
      slidesPerView: 1,
      spaceBetween: 40,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 60
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 60,
    },
    1200: {
      slidesPerView: 3,
      spaceBetween: 60,
    }
  }
  
  return (
      <div className={`container relative list-plans ${props.className}`}>
        <Swiper
          spaceBetween={60}
          slidesPerView={3}
          breakpoints={breakpoints}
          pagination={{ el: ".list-plans .swiper-pagination", clickable: true }}
        >
          <SwiperSlide className="swiper-slide content-item swiper-slide-active">
            <div className="text-center mb-20">
              <p className="flex ai-fc jc-fc">
                <span className="mr-10">R$</span> <b className="price-big">79</b> <span
                className="ml-10">Mês</span>
              </p>
              <p>Até <b>25</b> parceiros</p>
              <p><b>50</b> imóveis</p>
            </div>
            <ul className="mb-30 grid sm-gap">
              <li className="flex ai-fc">Página de Captura Ilimitadas</li>
              <li className="flex ai-fc">Cadastre até 25 mil Leads</li>
              <li className="flex ai-fc">Domínio Personalizado</li>
              <li className="flex ai-fc">CRM, Pipeline e Gestão de Lead</li>
              <li className="flex ai-fc">Receba Leads por E-mail e Whatsapp</li>
              <li className="flex ai-fc">Integração via API com seu site</li>
              <li className="flex ai-fc">Integração c/ Instagram e Facebook Ads</li>
            </ul>
            <Button url="https://portal.rankim.com/" className="btn big full action bolder" text="Escolher" />
          </SwiperSlide>
          <SwiperSlide className="swiper-slide content-item swiper-slide-next w-plan">
            <div className="text-center mb-20">
              <p className="flex ai-fc jc-fc">
                <span className="mr-10">R$</span> <b className="price-big">249</b> <span
                className="ml-10">Semestral</span>
              </p>
              <p>Até <b>25</b> parceiros</p>
              <p><b>50</b> imóveis</p>
            </div>
            <ul className="mb-30 grid sm-gap">
              <li className="flex ai-fc">Página de Captura Ilimitadas</li>
              <li className="flex ai-fc">Cadastre até 25 mil Leads</li>
              <li className="flex ai-fc">Domínio Personalizado</li>
              <li className="flex ai-fc">CRM, Pipeline e Gestão de Lead</li>
              <li className="flex ai-fc">Receba Leads por E-mail e Whatsapp</li>
              <li className="flex ai-fc">Integração via API com seu site</li>
              <li className="flex ai-fc">Integração c/ Instagram e Facebook Ads</li>
            </ul>
            <Button url="https://portal.rankim.com/" className="btn big full action bolder" text="Escolher" />
          </SwiperSlide>
          <SwiperSlide className="swiper-slide content-item w-plan">
            <div className="text-center mb-20">
              <p className="flex ai-fc jc-fc">
                <span className="mr-10">R$</span> <b className="price-big">499</b> <span
                className="ml-10">Mês</span>
              </p>
              <p>Até <b>100</b> parceiros</p>
              <p><b>5000</b> imóveis</p>
            </div>
            <ul className="mb-30 grid sm-gap">
              <li className="flex ai-fc">Página de Captura Ilimitadas</li>
              <li className="flex ai-fc">Cadastre até 25 mil Leads</li>
              <li className="flex ai-fc">Domínio Personalizado</li>
              <li className="flex ai-fc">CRM, Pipeline e Gestão de Lead</li>
              <li className="flex ai-fc">Receba Leads por E-mail e Whatsapp</li>
              <li className="flex ai-fc">Integração via API com seu site</li>
              <li className="flex ai-fc">Integração c/ Instagram e Facebook Ads</li>
            </ul>
            <Button url="https://portal.rankim.com/" className="btn big full action bolder" text="Escolher" />
          </SwiperSlide>
        </Swiper>
        <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-lock"/>
      </div>
    );
}

export default ListPlans;