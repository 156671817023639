import React, { useEffect } from 'react';
import '../assets/css/cookies.scss';

function Cookies() {
  useEffect(() => {
    (() => {
      const body = document.body;
      const containerCookies = document.querySelector(".container-cookies");
      const btnCookiesAccept = document.querySelector(".btn-cookies-accept");
      const btnCookiesCancel = document.querySelector(".btn-cookies-cancel");
      if (!localStorage.pureJavaScriptCookies) {
        containerCookies.classList.remove('hide');
        body.style.overflow = 'hidden';
      }
      const acceptCookies = (accept = false) => {
        containerCookies.classList.add('hide');
        body.style.overflow = 'auto';
        if (accept) localStorage.setItem("pureJavaScriptCookies", "accept");
      };
      btnCookiesAccept.addEventListener('click', () => acceptCookies(true));
      btnCookiesCancel.addEventListener('click', () => acceptCookies(false));
    })();
  })
    return (
      <div className="container-cookies hide">
        <div className="container large box-cookies flex ai-fc jc-sb">
          <p className="msg-cookies flex ai-fc ">
            A rankim usa Cookies, pequenos arquivos, para aprimorar e proteger a sua experiência. Clicando em "Aceitar" você concorda em armazenar Cookies no seu dispositivo.
          </p>
          <div className="flex ai-fc ">
            <button className="btn-cookies btn-cookies-accept">Aceitar</button>
            <button className="btn-cookies btn-cookies-cancel">Cancelar</button>
          </div>
        </div>
      </div>
  );
}

export default Cookies;