
import './errors.css';

function error404() {
    return (
        <div className="error404">
            error404
        </div>
    );
}

export default error404;
