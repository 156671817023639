import React, {useState} from 'react';
import '../assets/css/footer.scss'
import Logo from './Logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import {library} from "@fortawesome/fontawesome-svg-core";
import {useHistory} from "react-router-dom";
import Login from '../components/modals/Login';
import config from '../config/config';

library.add(fas);

function Footer() {

  // Modal Login
  const [showLogin, setShowLogin] = useState(false);
  const openModalLogin = (event) => {
    event.preventDefault();
    setShowLogin(!showLogin);
  }
  // Modal Login
  
  const history = useHistory();
  const search = (event, negotiation) => {
    event.preventDefault();
    localStorage.setItem('filterStorage', JSON.stringify({negotiation: negotiation}));
    history.push("/busca");
  }
  return (
    <footer className="pt-30">
      <Login show={showLogin} hideModal={openModalLogin}/>
      <div className="container bg-large md-pd pt-30 pb-20 grid sm-gap col-12">
        <div className="span-4">
          <div className="mb-20">
            <Logo lightGreen="true" />
          </div>
          <div className="pr-60">
            <p>
              A rankim vem revolucionando o mercado imobiliário há quase uma década. Já somos a maior
              empresa de tecnologia no ramo imobiliário do país, especializada em compra, venda e
              anúncios estratégicos. 
            </p>
            <p>
              Através da tecnologia, reinventamos os processos imobiliários para que cada experiência seja
              rápida, eficaz e segura!
            </p>
          </div>

          <div className="social-icons mt-35">
            <a href="https://www.facebook.com/voceafrente/" className="a-icon" target="_blank" rel="nofollow noopener noreferrer">
              <FontAwesomeIcon className="icon i-24" icon={['fab', 'facebook-f']} />
            </a>
            <a href="https://www.instagram.com/sejarankim/" className="a-icon" target="_blank" rel="nofollow noopener noreferrer">
              <FontAwesomeIcon className="icon i-24 ml-20" icon={['fab', 'instagram']} />
            </a>
            <a href="https://www.linkedin.com/company/rankim/" className="a-icon" target="_blank" rel="nofollow noopener noreferrer">
              <FontAwesomeIcon className="icon i-24 ml-20" icon={['fab', 'linkedin-in']} />
            </a>  
          </div>
        </div>

        <div className="span-2">
          <div className="title-green mb-20">
            Navegação
          </div>
          <ul className="grid sm-gap">
            <li>
              <a href="/anunciar">Anuncie grátis</a>
            </li>
            <li>
              <a href="/busca" onClick={(evt) => search(evt, '1')}>Comprar</a>
            </li>
            <li>
              <a href="/busca" onClick={(evt) => search(evt, '2')}>Alugar</a>
            </li>
            {config.isAuthenticated ? '' : <li>
              <a href="https://portal.rankim.com/#" onClick={openModalLogin}>Minha conta</a>
            </li>}
          </ul>
        </div>

        <div className="span-2">
          <div className="title-green mb-20">
            Institucional
          </div>
          <ul className="grid sm-gap">
            <li>
              <a href="https://sejarankim.com.br" target="_blank" rel="noreferrer">Corretor parceiro</a>
            </li>
            <li>
              <a href="https://rankim.com.br/blog" target="_blank" rel="nofollow noopener noreferrer">Conteúdo rico</a>
            </li>
            <li>
              <a href="https://linkedin.com/company/rankim/" target="_blank" rel="nofollow noopener noreferrer">Carreiras</a>
            </li>
          </ul>
        </div>

        <div className="span-2">
          <div className="title-green mb-20">
            Suporte
          </div>
          <ul className="grid sm-gap">
            <li>
              <a href="/termos-de-uso">Termos de uso</a>
            </li>
            <li>
              <a href="https://github.com/rankim-software" target="_blank" rel="nofollow noopener noreferrer">API de integração</a>
            </li>
          </ul>
        </div>

        <div className="span-2">
          <div className="title-green mb-20">
            Contato
          </div>
          <ul className="grid sm-gap">
            <li>
              <a href="https://api.whatsapp.com/send?phone=+5548991936723&text=Ol%C3%A1%20gostaria%20de%20receber%20atendimento." target="_blank" rel="nofollow noopener noreferrer">Fale conosco</a>
            </li>
          </ul>
        </div>  
        <div className="span-12">
          <div className="div-privacity text-right">Launched by <a href="https://phpenterprise.com/"  rel="noreferrer" target="_blank">PHP Enterprise </a>
            <FontAwesomeIcon className="icon ml-5" icon={['fas', 'rocket']} />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;