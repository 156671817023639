import React from 'react';
import '../assets/css/button.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Button(props) {
    let text = props.text;
    let disabled = props.disabled ? 'disabled' : '';
    if (props.icon) {
        text = <div className="btn-t">
            <FontAwesomeIcon className="icon float-left" icon={props.icon} />
            <div className="float-left va-m">{props.text}</div>
        </div>;
    }
    return (
        <a href={props.url} onClick={props.onClickButton} alt={props.text} className={`button ${ props.className } ${disabled}`}>{ text }</a>
    );
};

export default Button;