import './search.css';
import Header from "../../components/Header";
import PropertyCard from "../../components/property/Card";
import SearchComponent from '../../components/Search';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState, useRef } from 'react';
import ReactPaginate from 'react-paginate';
import axios from 'axios';
import Maps from "../../components/Maps";
import config from '../../config/config';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import NumberFormat from 'react-number-format';
import 'pure-css-loader/src/loader-default.sass';
import { Helmet } from "react-helmet";

function Search(props) {

    /* eslint-disable */
    let title = 'Últimas oportunidades cadastradas';

    const [pager, setPager] = useState({
        page: 1,
        total_pages: 1,
        total_rows: 1,
    });
    const [page, setPage] = useState(1)

    const handlePageClick = (e) => {
        setPage(e.selected + 1);
    };

    // Order Filter
    const [orderFilter, setOrderFilter] = useState(1);
    const handleChangeOrder = (event) => {
        setOrderFilter(event.target.value);
        setPage(1);
    }

    let total = config.totals?.totals.find(t => t.tipo === config.negotiations[1]) || {};
    const [min, setMin] = useState(parseInt(total?.valor_min));
    const [max, setMax] = useState(parseInt(total?.valor_max));

    // Order Filter
    const filterStorage = localStorage['filterStorage'] ? JSON.parse(localStorage['filterStorage']) : {};
    const [filtersDefault, setFiltersDefault] = useState({
        type: '1',
        text: '',
        property_value: null,
        negotiation: '1',
        ruler: '1',
        value: {
            min: min,
            max: max
        },
        area: {
            min: 0,
            max: 1000
        },
        uf: "",
        city: "",
        city_text: "",
        neighborhood: "",
        area_min_input: 0,
        area_max_input: 1000,
        value_min_input: min,
        value_max_input: max,
        value_min_input_old: min,
        value_max_input_old: max,
        rooms_1: false,
        rooms_2: false,
        rooms_3: false,
        rooms_4: false,
        bathrooms_1: false,
        bathrooms_2: false,
        bathrooms_3: false,
        bathrooms_4: false,
        garages_1: false,
        garages_2: false,
        garages_3: false,
        garages_4: false
    });

    // Filters
    const [filter, setFilter] = useState({
        ...filtersDefault, ...filterStorage, ...{
            uf: props.params?.uf?.toUpperCase() || "",
            city_text: props.params?.city?.split('.')[0] || "",
        }
    });

    if (filter.negotiation === '') filter.negotiation = '1';
    const refSearch = useRef(null);

    const handleInputChange = (event, format = 0) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (format === 1 || format === true) {
            let key = name.split('_')[0];
            let newObj = filter[key];
            newObj[name.match(/\_min\_/ig) ? 'min' : 'max'] = value;
            setFilter({ ...filter, ...{ key: newObj }, ...{ [name]: parseInt(value) } });
        } else if (format === 2) {
            let key = name.split('_')[0];
            let newObj = filter[key];
            newObj[name.match(/\_min\_/ig) ? 'min' : 'max'] = value;
            setFilter({ ...filter, ...{ key: newObj }, ...{ [name]: value } });
        } else {
            setFilter({ ...filter, ...{ [name]: value } });
        }
    }

    const handleBlurValue = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const item = name === 'value_min_input' ? 'value_min_input_old' : 'value_max_input_old';
        const v = parseInt(value.replace(/\./g, ''));
        if (v >= min && v <= max) {
            let newObj = filter.value;
            newObj[name === 'value_min_input' ? 'min' : 'max'] = v;
            setFilter({ ...filter, ...{ value: newObj }, ...{ [item]: newObj[name === 'value_min_input' ? 'min' : 'max'] } });
        } else {
            setFilter({ ...filter, ...{ [name]: filter[item] } });
        }
    }

    const handleBlurValuearea = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const item = name === 'area_min_input' ? 'area_min_input' : 'area_max_input';
        const v = parseInt(value);
        if (v >= min && v <= max) {
            let newObj = filter.area;
            newObj[name === 'area_min_input' ? 'min' : 'max'] = v;
            setFilter({ ...filter, ...{ area: newObj }, ...{ [item]: newObj[name === 'area_min_input' ? 'min' : 'max'] } });
        } else {
            setFilter({ ...filter, ...{ [name]: filter[item] } });
        }
    }

    const handleKeyPressValue = (event) => {
        if (event.key === 'Enter') {
            handleBlurValue(event);
        }
    }

    const onChangeRange = range => {
        if (range.max > max) range.max = max;
        if (range.min < min) range.min = min;
        setFilter({
            ...filter, ...{ value: range }, ...{ value_min_input: range.min }, ...{ value_max_input: range.max },
            ...{ value_min_input_old: range.min }, ...{ value_max_input_old: range.max }
        });
    }

    const onChangeRangearea = range => {
        if (range.max > 1000) range.max = 1000;
        if (range.min < 0) range.min = 0;
        setFilter({
            ...filter, ...{ area: range }, ...{ area_min_input: range.min }, ...{ area_max_input: range.max }
        });
    }

    const [orders, setOrders] = useState([]);
    const ORDERS_URI = `${config.api}/orders?access_token=${config.access_token}`;

    async function getData() {
        const getOrders = await axios.get(`${ORDERS_URI}`);
        setOrders(getOrders.data.response);
    }

    const [propertiesList, setPropertiesList] = useState([]);
    const SEARCH_URI = `${config.api}/search?access_token=${config.access_token}`;
    const [loading, setLoading] = useState(true);

    async function getSearch(e, init = false, filtros = false, novo = false) {
        let filterStorageTemp = localStorage['filterStorage'] ? JSON.parse(localStorage['filterStorage']) : {};
        let filterTemp = !novo ? { ...filtersDefault, ...filterStorageTemp, ...filter } : filter;
        setLoading(true);
        e?.preventDefault();
        if (filtros) {
            filterTemp.text = '';
            refSearch?.current?.clear();
        }
        let filters = '';
        if (page) filters += `&page=${init ? 1 : page}`;
        if (orderFilter) filters += `&order=${orderFilter}`;
        if (filterTemp?.negotiation) filters += `&negotiation=${filterTemp?.negotiation}`;
        if (!filtros && filterTemp?.text) filters += `&text=${(filterTemp?.text).replace(/[\(\)\<\>\"\'\/\\]+/ig, '')}`;

        const rooms = checkBedrooms(filterTemp);
        if (rooms) filters += `&bedrooms=${rooms}`;

        const bathrooms = checkBathrooms(filterTemp);
        if (bathrooms) filters += `&bathrooms=${bathrooms}`;

        const garages = checkGarages(filterTemp);
        if (garages) filters += `&garages=${garages}`;

        if (filterTemp?.uf) filters += `&uf=${filterTemp.uf}`;
        if (filterTemp?.neighborhood) filters += `&neighborhood=${filterTemp.neighborhood}`;
        if (filterTemp?.city) filters += `&city=${filterTemp.city}`;
        if (filterTemp?.city_text) filters += `&city_text=${filterTemp.city_text}`;
        if (filterTemp?.property_type) filters += `&category=${filterTemp.property_type}`;

        if (filterTemp?.value_min_input) filters += `&value_min=${(filterTemp?.value_min_input).toString().replace(/\./g, "")}`;
        if (filterTemp?.value_max_input) filters += `&value_max=${(filterTemp?.value_max_input).toString().replace(/\./g, "")}`;

        if (filterTemp?.area_min_input) filters += `&area_min=${filterTemp?.area_min_input}`;
        if (filterTemp?.area_max_input) filters += `&area_max=${filterTemp?.area_max_input}`;

        const res = await axios.get(`${SEARCH_URI}${filters}`);
        setPager(res.data.pager);
        setPropertiesList(res.data.response);
        setFilter(filterTemp);
        localStorage.setItem('filterStorage', JSON.stringify(filterTemp));
        setLoading(false);
    }

    function checkBedrooms(filterTemp) {
        let retorno = ''
        if (filterTemp?.rooms_1 || filterTemp?.rooms_2 || filterTemp?.rooms_3 || filterTemp?.rooms_4) {
            for (let i = 1; i <= 4; i++) {
                if (filterTemp['rooms_' + i]) {
                    retorno += (retorno ? ',' : '') + i
                }
            }
        }
        return retorno;
    }

    function checkGarages(filterTemp) {
        let retorno = ''
        if (filterTemp?.garages_1 || filterTemp?.garages_2 || filterTemp?.garages_3 || filterTemp?.garages_4) {
            for (let i = 1; i <= 4; i++) {
                if (filterTemp['garages_' + i]) {
                    retorno += (retorno ? ',' : '') + i
                }
            }
        }
        return retorno;
    }

    function checkBathrooms(filterTemp) {
        let retorno = ''
        if (filterTemp?.bathrooms_1 || filterTemp?.bathrooms_2 || filterTemp?.bathrooms_3 || filterTemp?.bathrooms_4) {
            for (let i = 1; i <= 4; i++) {
                if (filterTemp['bathrooms_' + i]) {
                    retorno += (retorno ? ',' : '') + i;
                }
            }
        }
        return retorno;
    }

    useEffect(() => {
        const filterButton = document.querySelector('.property-filter-btn');
        const filterForm = document.querySelector('.property-filter-options');
        const filterClose = document.querySelector('.close-filter-options');
        const propertyResults = document.querySelector('.property-results');
        const mapSearch = document.querySelector('.map-search');
        const closeMap = document.querySelector('.close-map');
        const openMap = document.querySelector('.open-map');
        const propertyThumb = document.querySelector('.property-thumb-slider');

        if (filterButton) {
            filterButton.addEventListener('click', e => {
                e.preventDefault();
                if (mapSearch.classList.contains('d-none')) {
                    setOpenMap();
                }
                filterForm.classList.toggle('active');
                filterButton.classList.toggle('active');
            });

            filterClose.addEventListener('click', e => {
                e.preventDefault();
                filterForm.classList.remove('active');
                filterButton.classList.remove('active');
            })
        }

        if (closeMap) {
            closeMap.addEventListener('click', e => {
                e.preventDefault();
                mapSearch.classList.add('d-none');
                openMap.classList.remove('d-none');
                propertyResults.classList.remove('span-7');
                propertyResults.classList.add('span-12');
                if (propertyThumb) propertyThumb.classList.remove('property-with-map');
            });
        }

        if (openMap) {
            openMap.addEventListener('click', e => {
                e.preventDefault();
                setOpenMap();
            });
        }

        function setOpenMap() {
            if (!openMap.classList.contains('d-none')) openMap.classList.add('d-none');
            mapSearch.classList.remove('d-none');
            propertyResults.classList.remove('span-12');
            propertyResults.classList.add('span-7');
            if (propertyThumb) propertyThumb.classList.add('property-with-map');
        }

        const cleanFilters = document.querySelector('.clean-filters');

        if (cleanFilters) {
            cleanFilters.addEventListener('click', e => {
                e.preventDefault();
                localStorage.setItem('filterStorage', JSON.stringify({}));
                setFilter(({ ...filtersDefault, ...{ uf: '', city: '', city_text: '', neighborhood: '' } }));
            });
        }

        (async function getDataAsync() {
            await getData();
        })();
    }, [])

    useEffect(() => {
        (async function getDataAsync() {
            await getSearch();
        })();
    }, [page, orderFilter])

    const [loadRange, setLoadRange] = useState(true);

    useEffect(() => {
        setLoadRange(true);
        let total = config.totals?.totals.find(t => t.tipo === config.negotiations[filter.negotiation || 1]) || {};
        setMax(parseInt(total?.valor_max));
        setMin(parseInt(total?.valor_min));
        let filtersDefaultTemp = {
            ...filtersDefault, ...{
                negotiation: filter.negotiation,
                value: {
                    min: parseInt(total?.valor_min),
                    max: parseInt(total?.valor_max)
                },
                value_min_input: parseInt(total?.valor_min),
                value_max_input: parseInt(total?.valor_max),
                value_min_input_old: parseInt(total?.valor_min),
                value_max_input_old: parseInt(total?.valor_max)
            }
        };
        setFiltersDefault(filtersDefaultTemp);
        setFilter({ ...filtersDefaultTemp });
        setLoadRange(false);
        if (filter.city) {
            getCities();
        }
        if (filter.neighborhood) {
            getNeighborhoods();
        }
    }, [filter.negotiation])

    const [cities, setCities] = useState([]);
    const [neighborhoods, setNeighborhoods] = useState([]);

    const getCities = async (e) => {
        e?.preventDefault();

        const { value } = e?.target || { value: filter?.uf || "" };

        if (!value) {
            return false;
        }

        setFilter(prevFilter => ({ ...prevFilter, city_text: '' }));

        const res = await axios.get(`${config.api}/cities/${value}?access_token=${config.access_token}`);

        setCities(res.data || []);
    }

    const getNeighborhoods = async (e) => {
        e?.preventDefault();

        const { value } = e?.target || { value: filter?.city || "" };

        if (!value) {
            return false;
        }

        const res = await axios.get(`${config.api}/neighborhoods/${value}?access_token=${config.access_token}`);

        setNeighborhoods(res.data || []);
    }

    useEffect(() => { }, [cities, neighborhoods]);

    return (
        <div id="search">

            <Helmet>
                <meta charSet="utf-8" />
                <meta name="keywords" content="plataforma,automação,leads,corretor,whatsapp,landing page,captação de leads,crm corretor,crm imobiliária,marketing,rankim,geração de leads,corretor de imóveis" />
                <meta name="description" content="Plataforma de automação de marketing, captação de leads e CRM para corretores de imóveis e imobiliárias, conteúdo exclusivo" />
                <meta name="author" content="rankim" />
                <title>rankim: Plataforma de compra e venda de imóveis</title>
                <meta property="fb:app_id" content="" />
                <meta property="og:type" content="article" />
                <meta property="profile:username" content="voceafrente" />
                <meta property="og:title" content="Plataforma imobiliária de marketing e CRM para.." />
                <meta property="og:description" content="Plataforma de automação de marketing, captação.." />
                <meta property="og:url" content="https://rankim.com.br/" />
                <meta property="og:site_name" content="rankim" />
                <meta property="og:see_also" content="https://rankim.com.br/" />
                <meta property="og:image" content="https://rankim.com.br/assets/website/images/video/02.jpg" />
                <meta property="og:image:url" itemprop="image" content="https://rankim.com.br/assets/website/images/video/02.jpg" />
                <meta property="og:image:type" content="image/jpg" />
            </Helmet>

            <Header />

            <main>
                <div className="grid-search grid col-12">
                    <div className="span-7 property-results md-pd">
                        <SearchComponent defaultInputValue={filter.text} innerRef={refSearch} />

                        <div className="flex fw-w ai-fc jc-sb">
                            <h5 className="thin mb-30">{title}</h5>
                            <div className="mb-30">
                                <a href={config.url} className="property-filter-btn btn text-black bordered flex ai-fc bold">
                                    <FontAwesomeIcon className="icon mr-10" icon={['fas', 'sliders-h']} />
                                    Filtros
                                </a>

                                <a href={config.url} className="open-map btn bordered flex ai-fc ml-20 d-none">
                                    <FontAwesomeIcon className="icon mr-10" icon={['fas', 'map-marked-alt']} />
                                    Mapa
                                </a>
                            </div>
                        </div>

                        <hr className="mb-20" />

                        <div className="flex fw-w ai-fc jc-sb">
                            {!loading ? (<p className="mb-40">{pager.total_rows} Imóve{config.getPlural(pager.total_rows, 'l', 'is')} encontrado{config.getPlural(pager.total_rows, '', 's')} nessa região e arredores</p>) : ''}
                            <div className="select-group mb-40">
                                <FontAwesomeIcon className="icon i-48" icon={['fas', 'angle-down']} />
                                <select name="order" id="order" value={orderFilter} onChange={handleChangeOrder}>
                                    {orders.map(o => {
                                        return (<option key={o.id} value={o.id}>{o.label}</option>)
                                    })}
                                </select>
                            </div>
                        </div>
                        {loading ? <div className="loader loader-default is-active" /> : (
                            <div className={`property-thumb-slider grid col-3 sm-gap property-with-map`} style={{ height: 'auto' }}>
                                {propertiesList.map((property, e) => {
                                    return (
                                        <PropertyCard key={e} property={property} />
                                    )
                                })}
                            </div>)}

                        <div className="pagination-container">
                            {pager.total_pages < 2 ? '' : <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={pager.total_pages}
                                forcePage={pager.page - 1}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={0}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"} />}
                        </div>

                    </div>

                    <div className="span-5 map-search relative">
                        <div className="map-full">
                            <a href={config.url} className="close-map btn bordered flex ai-fc">
                                Fechar Mapa
                            </a>
                            <Maps zoom={7} properties={propertiesList} />
                        </div>

                        <div className="property-filter-options flex fd-fc">
                            <div className="property-filter-content">
                                <div className="flex ai-fc p-20">
                                    <a href={config.url} className="close-filter-options mr-30">
                                        <FontAwesomeIcon icon={['fas', 'times']} />
                                    </a>
                                    <h5 className="thin">Filtros</h5>
                                </div>
                                <form className="flex fd-fc jc-sb">
                                    <div>
                                        <div className="property-deal-type flex ai-fc">
                                            <label className="deal-type">
                                                <input type="radio" name="negotiation" id="buy"
                                                    checked={filter.negotiation === '1'} value="1" onChange={handleInputChange} />
                                                <span className="bolder big-text">Comprar</span>
                                            </label>
                                            <label className="deal-type">
                                                <input type="radio" name="negotiation" id="rent"
                                                    checked={filter.negotiation === '2'} value="2" onChange={handleInputChange} />
                                                <span className="bolder big-text">Alugar</span>
                                            </label>
                                        </div>

                                        <div className="p-40">
                                            <h5 className="thin mb-20">Valor</h5>
                                            <div className="property-min-max-value flex ai-fc fw-w jc-sb mb-20">
                                                <div className="input-group mb-20">
                                                    <label htmlFor="min-value">Mínimo</label>
                                                    <span className="rs bold">R$</span>
                                                    <NumberFormat name="value_min_input" id="value_min_input" className="text-right" value={filter.value_min_input}
                                                        thousandSeparator={'.'} decimalSeparator={','} onChange={(e) => handleInputChange(e, 2)} onBlur={handleBlurValue}
                                                        onKeyPress={handleKeyPressValue} />
                                                </div>
                                                <div className="input-group mb-20">
                                                    <label htmlFor="max-value">Máximo</label>
                                                    <span className="rs bold">R$</span>
                                                    <NumberFormat name="value_max_input" id="value_max_input" className="text-right" value={filter.value_max_input}
                                                        thousandSeparator={'.'} decimalSeparator={','} onChange={(e) => handleInputChange(e, 2)} onBlur={handleBlurValue}
                                                        onKeyPress={handleKeyPressValue} />
                                                </div>

                                                <div className="budget-value mb-40 p-10 w-100">
                                                    {loadRange ? '' : <InputRange id="range-value"
                                                        minValue={min || 0}
                                                        maxValue={max || 0}
                                                        onChange={onChangeRange}
                                                        value={filter?.value || { min: 0, max: 0 }} />}
                                                </div>
                                            </div>
                                            <hr className="mb-40" />
                                            <h5 className="thin mb-20">Tamanho em M²</h5>
                                            <div className="property-min-max-value flex ai-fc fw-w jc-sb mb-20">
                                                <div className="input-group mb-20">
                                                    <label htmlFor="min-value">Mínimo M²</label>
                                                    <span className="rs bold">M²</span>
                                                    <input type="number" name="area_min_input" id="area_min_input" className="text-right" value={filter.area_min_input}
                                                        onChange={(e) => handleInputChange(e, 1)} onBlur={handleBlurValuearea} />
                                                </div>
                                                <div className="input-group mb-20">
                                                    <label htmlFor="min-value">Máximo M²</label>
                                                    <span className="rs bold">M²</span>
                                                    <input type="number" name="area_max_input" id="area_max_input" className="text-right" value={filter.area_max_input}
                                                        onChange={(e) => handleInputChange(e, 1)} onBlur={handleBlurValuearea} />
                                                </div>

                                                <div className="budget-value mb-40 p-10 w-100">
                                                    {loadRange ? '' : <InputRange id="range-area"
                                                        minValue={0}
                                                        maxValue={1000}
                                                        onChange={onChangeRangearea}
                                                        value={filter?.area || { min: 0, max: 0 }} />}
                                                </div>
                                            </div>
                                            <hr className="mb-40" />
                                            <div className="property-type flex ai-fc fw-w jc-sb mb-40">
                                                <h5 className="thin">Tipo</h5>
                                                <div className="flex ai-fc radio-group">
                                                    <label key="type-1" className="flex ai-fc normal jc-fc">
                                                        <input type="radio" name="type" checked={parseInt(filter.type) === 1} value="1" onChange={handleInputChange} />
                                                        <span>Residencial</span>
                                                    </label>
                                                    <label key="type-2" className="flex ai-fc normal jc-fc">
                                                        <input type="radio" name="type" checked={parseInt(filter.type) === 2} value="2" onChange={handleInputChange} />
                                                        <span>Comercial</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <hr className="mb-40" />
                                            <div className="property-category flex ai-fc fw-w jc-sb mb-40">
                                                <h5 className="thin">Categoria</h5>
                                                <div className="flex ai-fc category">
                                                    <select name="property_type" onChange={handleInputChange} value={filter?.property_type || ''}>
                                                        <option key={`category-0`} value=''>Todas</option>
                                                        {(config.totals?.category || [])?.map((group) => {
                                                            if (parseInt(filter.type) === parseInt(group.id)) {
                                                                return group?.opcoes.map((option, i) => {
                                                                    return <option key={`category-${i}`} value={option.value}>{option.label}</option>;
                                                                });
                                                            }
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <hr className="mb-40" />
                                            <div className="property-uf flex ai-fc fw-w jc-sb mb-40">
                                                <h5 className="thin">Estado</h5>
                                                <div className="flex ai-fc uf">
                                                    <select name="uf" onChange={(e) => {
                                                        handleInputChange(e);
                                                        getCities(e);
                                                    }} value={filter?.uf || ''}>
                                                        <option key={`uf-0`} value=''>Todos</option>
                                                        {(config.totals?.uf || [])?.map((estado) => {
                                                            return <option key={`uf-${estado.value}`} value={estado.value}>{estado.label}</option>;
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <hr className="mb-40" />
                                            <div className="property-city flex ai-fc fw-w jc-sb mb-40">
                                                <h5 className="thin">Cidade</h5>
                                                <div className="flex ai-fc city">
                                                    <select name="city" disabled={!cities?.length} onChange={(e) => {
                                                        handleInputChange(e);
                                                        getNeighborhoods(e);
                                                    }} value={filter?.city || ''} style={{ maxWidth: '400px' }}>
                                                        <option key={`city-0`} value=''>Todas as cidades</option>;
                                                        {(cities || [])?.map((city) => {
                                                            return <option key={`city-${city.value}`} value={city.value}>{city.label}</option>;
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <hr className="mb-40" />
                                            <div className="property-neighborhood flex ai-fc fw-w jc-sb mb-40">
                                                <h5 className="thin">Bairro</h5>
                                                <div className="flex ai-fc neighborhood">
                                                    <select name="neighborhood" disabled={!neighborhoods?.length} onChange={handleInputChange} defaultValue={filter?.neighborhood || ''} style={{ maxWidth: '400px' }}>
                                                        <option key={`neighborhood-0`} value="">Todos os bairros</option>;
                                                        {(neighborhoods || [])?.map((neighborhood) => {
                                                            return <option key={`neighborhood-${neighborhood.value}`} value={neighborhood.value}>{neighborhood.label}</option>;
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <hr className="mb-40" />
                                            <div className="property-rooms flex ai-fc fw-w jc-sb mb-40">
                                                <h5 className="thin">Dormitórios</h5>
                                                <div className="flex ai-fc room-qnty">
                                                    <label className="flex ai-fc normal">
                                                        <input type="checkbox" name="rooms_1"
                                                            checked={filter.rooms_1} onChange={handleInputChange} />
                                                        <span className="mid-text">+1</span>
                                                    </label>
                                                    <label className="flex ai-fc normal">
                                                        <input type="checkbox" name="rooms_2"
                                                            checked={filter.rooms_2} onChange={handleInputChange} />
                                                        <span className="mid-text">+2</span>
                                                    </label>
                                                    <label className="flex ai-fc normal">
                                                        <input type="checkbox" name="rooms_3"
                                                            checked={filter.rooms_3} onChange={handleInputChange} />
                                                        <span className="mid-text">+3</span>
                                                    </label>
                                                    <label className="flex ai-fc normal">
                                                        <input type="checkbox" name="rooms_4"
                                                            checked={filter.rooms_4} onChange={handleInputChange} />
                                                        <span className="mid-text">+4</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <hr className="mb-40" />
                                            <div className="property-bathrooms flex fw-w ai-fc jc-sb mb-40">
                                                <h5 className="thin">Banheiros</h5>
                                                <div className="flex ai-fc room-qnty">
                                                    <label className="flex ai-fc normal">
                                                        <input type="checkbox" name="bathrooms_1"
                                                            checked={filter.bathrooms_1} onChange={handleInputChange} />
                                                        <span className="mid-text">+1</span>
                                                    </label>
                                                    <label className="flex ai-fc normal">
                                                        <input type="checkbox" name="bathrooms_2"
                                                            checked={filter.bathrooms_2} onChange={handleInputChange} />
                                                        <span className="mid-text">+2</span>
                                                    </label>
                                                    <label className="flex ai-fc normal">
                                                        <input type="checkbox" name="bathrooms_3"
                                                            checked={filter.bathrooms_3} onChange={handleInputChange} />
                                                        <span className="mid-text">+3</span>
                                                    </label>
                                                    <label className="flex ai-fc normal">
                                                        <input type="checkbox" name="bathrooms_4"
                                                            checked={filter.bathrooms_4} onChange={handleInputChange} />
                                                        <span className="mid-text">+4</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <hr className="mb-40" />
                                            <div className="property-garages flex fw-w ai-fc jc-sb">
                                                <h5 className="thin">Vagas</h5>
                                                <div className="flex ai-fc garages-qnty">
                                                    <label className="flex ai-fc normal">
                                                        <input type="checkbox" name="garages_1"
                                                            checked={filter.garages_1} onChange={handleInputChange} />
                                                        <span className="mid-text">+1</span>
                                                    </label>
                                                    <label className="flex ai-fc normal">
                                                        <input type="checkbox" name="garages_2"
                                                            checked={filter.garages_2} onChange={handleInputChange} />
                                                        <span className="mid-text">+2</span>
                                                    </label>
                                                    <label className="flex ai-fc normal">
                                                        <input type="checkbox" name="garages_3"
                                                            checked={filter.garages_3} onChange={handleInputChange} />
                                                        <span className="mid-text">+3</span>
                                                    </label>
                                                    <label className="flex ai-fc normal">
                                                        <input type="checkbox" name="garages_4"
                                                            checked={filter.garages_4} onChange={handleInputChange} />
                                                        <span className="mid-text">+4</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="grid-footer-filters grid col-3 sm-gap ai-fc ji-gc p-20 text-center">
                                        <a href="https://portal.rankim.com/" className="clean-filters btn bordered flex ai-fc">Limpar filtros</a>
                                        <span>{pager.total_rows} Resultado{config.getPlural(pager.total_rows, '', 's')}</span>
                                        <a href="https://portal.rankim.com/" className="apply-filters btn action" onClick={(e) => getSearch(e, true, true, true)}>Aplicar filtros</a>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>

                </div>

            </main>

        </div>
    );
}

export default Search;