import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, {useState} from "react";
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import '../assets/css/timepicker.scss';

SwiperCore.use([Navigation]);


function Timepicker(props) {
  let times = [];
  let start = props?.start || 7
  let end = props?.end || 20;
  while (start <= end) {
    times.push({
      'time': `${start < 10 ? '0' : ''}${start}:00`
    });
    start++;
  }
  
  const [time, setTime] = useState([]);
  
  return (
      <div className={"timepicker-component relative " + (props.className || '')}>
        <Swiper className="timepicker-slider"
                navigation= {{nextEl: '.timepicker-next', prevEl: '.timepicker-prev'}}
                slidesPerView="auto" grabCursor={true} effect="coverflow"
                spaceBetween={28}>
          {(times || []).map((t, i) => {
            return (
              <SwiperSlide key={`img-${i}`} style={{width: '114px'}}>
                <div className={`timepicker ${time === t.time ? 'selected' : ''} ${props?.classTime || ''}`}
                     onClick={
                              () => {
                                props.onSetTime?.(t.time);
                                setTime(t.time)
                              }
                            }>
                  <div className="time">{t.time}</div>
                </div>
              </SwiperSlide> )
          })
          }
        </Swiper>
        <div className="swiper-button-prev timepicker-prev"/>
        <div className="swiper-button-next timepicker-next"/>
      </div>
    );
}

export default Timepicker;