import React, {useEffect, useState} from "react";
import Button from "../../../components/Button";
import PhoneInput from 'react-phone-input-2';
import pt from 'react-phone-input-2/lang/pt.json';
import NumberFormat from "react-number-format";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../../assets/css/modals/components/loginRealtor.scss';
import Logo from "../../Logo";

function LoginRealtor(props) {
  
  // Phone
  const [isDialCode, setIsDialCode] = useState(true);
  const [cc, setCc] = useState('55');
  const [ddd, setDdd] = useState('');
  const [phone, setPhone] = useState('');
  
  function handleOnChange(value, data) {
    let selectedFlag = document.querySelector('.selected-flag');
    selectedFlag.title = `${pt[data.countryCode]}: +${data.dialCode}`;
    setIsDialCode(value === data.dialCode);
    setCc(value)
  }
  
  function validateKeyCode(evt) {
    let charCode = (evt.which) ? evt.which : evt.keyCode
    if ((isDialCode || evt.target.value?.length > 4) && ![8, 46, 37, 38, 39, 40].includes(charCode)) evt.preventDefault();
  }
  
  function validateDialCode(evt) {
    if (!isDialCode) setCc('55');
  }
  
  useEffect(() => {
    let selectedFlag = document.querySelector('.selected-flag');
    if(selectedFlag) selectedFlag.title = `Brasil: +55`;
  }, [])
  // Phone

  return (
    <div className="container small">
        <div className="register-realtor-header text-center mb-80">
          <a className="back" href="https://portal.rankim.com/#" onClick={props.hideModal}>
            <FontAwesomeIcon className="icon mr-10" icon={['fas', 'arrow-left']} /> Voltar
          </a>
          <Logo className="mt-100 d-block disabled" />
          <h1 className="mb-30">Acesse ou Crie sua conta</h1>
          <div className="sub-title">Digite seu celular para entrar. Seus dados estão seguros e você não precisa de senha.</div>
        </div>
        <div className="grid ai-gc">
          <div className="grid col-12 sm-gap mb-20">
            <div className="span-3 input-group">
              <label htmlFor="cc">País</label>
              <PhoneInput
                value={cc}
                enableSearch={true}
                searchPlaceholder={'Procurar'}
                searchNotFound={'Sem Resultados'}
                localization={pt}
                placeholder="" onChange={handleOnChange}
                onKeyDown={validateKeyCode}
                onBlur={validateDialCode}
                autoFormat={false}
              />
            </div>
            <div className="span-3 input-group">
              <label htmlFor="ddd">DDD</label>
              <NumberFormat value={ddd} onChange={a => setDdd(a.target.value)} />
            </div>
            <div className="span-6 input-group">
              <label htmlFor="phone">Telefone</label>
              <NumberFormat value={phone} onChange={a => setPhone(a.target.value)} />
            </div>
          </div>

          <span className="info-phone w-70">Digite seu celular para entrar. Seus dados estão seguros e você não precisa de senha.</span>

          <Button onClickButton={() => props.send({type: 1, cc, ddd, phone})} className="btn info w-70" text="Receber código por SMS" />
          {/* <Button onClickButton={() => props.send({type: 2, cc, ddd, phone})} className="btn action mt-20 w-70" text="Receber código por WhatsApp" /> */}
        </div>
      </div>
    );
}

export default LoginRealtor;