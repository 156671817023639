import React, { useEffect, useState } from "react";
import ReactModal from 'react-modal';
import '../../assets/css/modals/registerRealtor.scss';
import LoginRealtor from "./components/LoginRealtor";
import LoginRealtorCode from "./components/LoginRealtorCode";
import PersonalData from "./components/PersonalData";
import Acting from "./components/Acting";
import Notifications from "./components/Notifications";
import Integrations from "./components/Integrations";
import Plans from "./components/Plans";

function RegisterRealtor(props) {
  const [component, setComponent] = useState();

  useEffect(() => {

    const setup = function (stage, form = {}) {
      switch (stage) {
        case 1:
          setComponent(<PersonalData close={props.hideModal} stage={stage} setup={setup} form={form} />);
          break;
        case 2:
          setComponent(<Acting stage={stage} setup={setup} form={form} />);
          break;
        case 3:
          setComponent(<Notifications stage={stage} setup={setup} form={form} />);
          break;
        case 4:
          setComponent(<Integrations stage={stage} setup={setup} form={form} />);
          break;
        case 5:
          setComponent(<Plans stage={stage} setup={setup} form={form} />);
          break;
        default: break;

      }
    }

    const backLogin = function () {
      setComponent(<LoginRealtor send={sendCode} hideModal={props.hideModal} />);
    }

    const sendCode = function (data) {
      setComponent(<LoginRealtorCode back={backLogin} data={data} send={sendCode} setup={setup} />);
    }

    setComponent(<LoginRealtor send={sendCode} hideModal={props.hideModal} />);
  }, [props.show, props.hideModal]);

  return (
    <ReactModal
      isOpen={props.show}
      contentLabel="Cadastrar Corretor"
      ariaHideApp={false}
      overlayClassName="overlay-register-realtor"
      className="modal-register-realtor"
    >
      {component}
    </ReactModal>
  );
};

export default RegisterRealtor;