import React, { useState } from "react";
import config from "../../../config/config";
import Button from "../../../components/Button";
import Phone from "../../Phone";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../../assets/css/modals/components/login.scss';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import Cookies from 'js-cookie'

function Login(props) {
  const LOGIN_URI = `${config.api}/login?access_token=${config.access_token}`
  const CONFIRM_URI = `${config.api}/confirm?access_token=${config.access_token}`
  const phoneId = 'loginModal';
  const [dto, setDto] = useState({
    cc: '55',
    phone: '',
    code: ''
  });

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setDto({ ...dto, ...{ [name]: value } });
  };

  const [retorno, setRetorno] = useState({ status: false, usuario: '' });
  async function sendCodeSMS() {
    let phoneInput = document.getElementById('phone-' + phoneId);
    if (config.isEmptyNullOrUndefined(dto.phone)) {
      phoneInput.classList.add('danger-border')
    } else {
      setSendingCode(true);
      let filters = `&phone=${dto.cc + (dto.phone).replace(/\D/g, "")}`;
      const res = await axios.post(`${LOGIN_URI}${filters}`);
      if (res.data?.response?.status) {
        Cookies.set("phone", dto.cc + (dto.phone).replace(/\D/g, ""), { expires: 1, path: '/' });
        setRetorno(res.data.response);
        phoneInput.classList.remove('danger-border')
      } else {
        if (res.data?.errors) {
          phoneInput.classList.add('danger-border');
        }
      }
      setSendingCode(false);
    }
  }

  async function confirmCode(e) {
    let codeInput = document.getElementById('code-' + phoneId);

    if (config.isEmptyNullOrUndefined(dto.code)) {
      codeInput.classList.add('danger-border')
    } else {
      setConfirmingCode(true);
      let filters = `&user=${retorno.usuario}&code=${dto.code}`;
      const res = await axios.post(`${CONFIRM_URI}${filters}`);
      if (res.data?.response?.status) {
        Cookies.set("user", retorno.usuario, { expires: 1, path: '/' });
        codeInput.classList.remove('danger-border');
        if (props?.setAuthenticated) {
          props.setAuthenticated(true);
        }
        if (props?.onPass && typeof props.onPass === 'function') {
          props.onPass(e);
        }
      } else {
        codeInput.classList.add('danger-border')
      }
      setConfirmingCode(false);
    }
  }

  const [sendingCode, setSendingCode] = useState(null);
  const [confirmingCode, setConfirmingCode] = useState(null);

  return (
    <div className="login">
      <div className="login-header text-center mb-80">
        <button onClick={props?.hideModal} className="pointer">
          <FontAwesomeIcon className="icon mr-10" icon={['fas', 'arrow-left']} />
        </button>
        <h1 className="mb-30">Entre ou Cadastre-se</h1>
        <div className="sub-title">{!retorno.status ? 'Digite seu celular para entrar' : 'Digite o código recebido por SMS'}</div>
      </div>
      {!retorno.status ? (
        <div className="grid ai-gc">
          <div className="mb-20">
            <Phone emit={(data) => setDto({ ...dto, ...data })} phoneId={phoneId} />
          </div>

          <span className="info-phone">Seus dados estão seguros e você não precisa de senha.</span>

          <Button onClickButton={sendCodeSMS} disabled={sendingCode} className="btn info w-100" text={`${sendingCode ? 'Enviando..' : 'Receber código por SMS'}`} />
        </div>
      ) : (
        <div className="grid ai-gc">
          <div className="mb-20">
            <div className="span-8 input-group">
              <label htmlFor={`code-` + phoneId}>Código de confirmação</label>
              <NumberFormat id={`code-` + phoneId} name="code" value={dto.code} onChange={handleInputChange} />
            </div>
          </div>
          <span className="info-phone">Seu código é valido por 60 segundos</span>
          <Button onClickButton={confirmCode} disabled={confirmingCode} className="btn action mt-20 w-100" text={`${confirmingCode ? 'Confirmando..' : 'Confirmar'}`} />
        </div>
      )}
      <div className="text-right mt-20">
        Entre como <a className="link" href="./corretor">corretor parceiro</a>
      </div>
    </div>
  );
}

export default Login;