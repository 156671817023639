import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function Stage(props) {
  
  const current = props.stage;
  
  let stages = [
    {id: 1, label: 'Dados'},
    {id: 2, label: 'Atuação'},
    {id: 3, label: 'Notificações'},
    {id: 4, label: 'Integrações'},
    {id: 5, label: 'Planos'},
  ]
  
  return (
    <div className="stage w-100">
      <ul className="flex jc-fc fw-w">
        {stages.map(stage => {
          return (<li key={stage.id} className={current === stage.id ? 'current' : current > stage.id ? 'completed' : null} >
            {current > stage.id ? <FontAwesomeIcon className="icon" icon={['fas', 'check']}/> : null}
            {stage.label}
          </li>)
        })}
      </ul>
    </div>
    );
}

export default Stage;